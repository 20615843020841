import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import FloorplanView from "../../components/floorplanView"

const FloorPlanPage = () => (
  <FloorplanView title="Type A Floor Plan">
    <StaticImage
      objectFit="contain"
      className="object-contain min-h-full h-screen w-auto"
      src="../../images/floorplan/a.jpg"
      alt="Floor plan"
      formats={["auto", "webp", "avif"]}
    />
  </FloorplanView>
)

export default FloorPlanPage
